import { render, staticRenderFns } from "./SectionComponent.vue?vue&type=template&id=6db200bf&"
import script from "./SectionComponent.vue?vue&type=script&lang=js&"
export * from "./SectionComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports