export const FONT_SIZES = [
    {
        name: 'Default',
        value: 'default'
    },
    {
        name: '8px',
        value: '8'
    },
    {
        name: '9px',
        value: '9'
    },
    {
        name: '10px',
        value: '10'
    },
    {
        name: '11px',
        value: '11'
    },
    {
        name: '12px',
        value: '12'
    },
    {
        name: '13px',
        value: '13'
    },
    {
        name: '14px',
        value: '14'
    },
    {
        name: '16px',
        value: '16'
    },
    {
        name: '18px',
        value: '18'
    },
    {
        name: '20px',
        value: '20'
    },
    {
        name: '24px',
        value: '24'
    },
    {
        name: '28px',
        value: '28'
    },
    {
        name: '32px',
        value: '32'
    },
    {
        name: '36px',
        value: '36'
    },
    {
        name: '42px',
        value: '42'
    },
    {
        name: '48px',
        value: '48'
    },
    {
        name: '72px',
        value: '72'
    },
    {
        name: '96px',
        value: '96'
    },
    {
        name: '128px',
        value: '128'
    }
];

export const FONTS = [
    {
        name: 'Default',
        value: 'default'
    },
    {
        name: 'Bold',
        value: 'default-bold'
    },
    {
        name: 'Narrow',
        value: 'narrow'
    },
    {
        name: 'Narrow Bold',
        value: 'narrow-bold'
    },
    {
        name: 'Monospace',
        value: 'monospace'
    },
    {
        name: 'Monospace Bold',
        value: 'monospace-bold'
    }
];
