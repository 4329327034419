var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-imagery",attrs:{"tabindex":"0"},on:{"keyup":_vm.arrowUpHandler,"keydown":_vm.arrowDownHandler,"mouseover":_vm.focusElement}},[_c('div',{staticClass:"c-imagery__main-image-wrapper has-local-controls"},[_c('div',{staticClass:"h-local-controls h-local-controls--overlay-content c-local-controls--show-on-hover c-image-controls__controls"},[_c('span',{staticClass:"c-image-controls__sliders",attrs:{"draggable":"true"},on:{"dragstart":_vm.startDrag}},[_c('div',{staticClass:"c-image-controls__slider-wrapper icon-brightness"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.brightness),expression:"filters.brightness"}],attrs:{"type":"range","min":"0","max":"500"},domProps:{"value":(_vm.filters.brightness)},on:{"__r":function($event){_vm.$set(_vm.filters, "brightness", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"c-image-controls__slider-wrapper icon-contrast"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.contrast),expression:"filters.contrast"}],attrs:{"type":"range","min":"0","max":"500"},domProps:{"value":(_vm.filters.contrast)},on:{"__r":function($event){_vm.$set(_vm.filters, "contrast", $event.target.value)}}})])]),_vm._v(" "),_c('span',{staticClass:"t-reset-btn-holder c-imagery__lc__reset-btn c-image-controls__btn-reset"},[_c('a',{staticClass:"s-icon-button icon-reset t-btn-reset",on:{"click":function($event){_vm.filters={brightness: 100, contrast: 100}}}})])]),_vm._v(" "),_c('div',{ref:"imageBG",staticClass:"c-imagery__main-image__bg",class:{'paused unnsynced': _vm.isPaused && !_vm.isFixed,'stale':false },on:{"click":_vm.expand}},[_c('div',{staticClass:"image-wrapper",style:({
                     'width': ((_vm.sizedImageDimensions.width) + "px"),
                     'height': ((_vm.sizedImageDimensions.height) + "px")
                 })},[_c('img',{ref:"focusedImage",staticClass:"c-imagery__main-image__image js-imageryView-image",style:({
                         'filter': ("brightness(" + (_vm.filters.brightness) + "%) contrast(" + (_vm.filters.contrast) + "%)")
                     }),attrs:{"src":_vm.imageUrl,"data-openmct-image-timestamp":_vm.time,"data-openmct-object-keystring":_vm.keyString}}),_vm._v(" "),(_vm.shouldDisplayCompass)?_c('Compass',{attrs:{"compass-rose-sizing-classes":_vm.compassRoseSizingClasses,"image":_vm.focusedImage,"natural-aspect-ratio":_vm.focusedImageNaturalAspectRatio,"sized-image-dimensions":_vm.sizedImageDimensions}}):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"c-local-controls c-local-controls--show-on-hover c-imagery__prev-next-buttons"},[_c('button',{staticClass:"c-nav c-nav--prev",attrs:{"title":"Previous image","disabled":_vm.isPrevDisabled},on:{"click":function($event){_vm.prevImage()}}}),_vm._v(" "),_c('button',{staticClass:"c-nav c-nav--next",attrs:{"title":"Next image","disabled":_vm.isNextDisabled},on:{"click":function($event){_vm.nextImage()}}})]),_vm._v(" "),_c('div',{staticClass:"c-imagery__control-bar"},[_c('div',{staticClass:"c-imagery__time"},[_c('div',{staticClass:"c-imagery__timestamp u-style-receiver js-style-receiver"},[_vm._v(_vm._s(_vm.time))]),_vm._v(" "),(_vm.canTrackDuration)?_c('div',{staticClass:"c-imagery__age icon-timer",class:{'c-imagery--new': _vm.isImageNew && !_vm.refreshCSS}},[_vm._v(_vm._s(_vm.formattedDuration))]):_vm._e(),_vm._v(" "),(_vm.relatedTelemetry.hasRelatedTelemetry && _vm.isSpacecraftPositionFresh)?_c('div',{staticClass:"c-imagery__age icon-check c-imagery--new"},[_vm._v("POS")]):_vm._e(),_vm._v(" "),(_vm.relatedTelemetry.hasRelatedTelemetry && _vm.isCameraPositionFresh)?_c('div',{staticClass:"c-imagery__age icon-check c-imagery--new"},[_vm._v("CAM")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"h-local-controls"},[(!_vm.isFixed)?_c('button',{staticClass:"c-button icon-pause pause-play",class:{'is-paused': _vm.isPaused},on:{"click":function($event){_vm.paused(!_vm.isPaused, 'button')}}}):_vm._e()])])]),_vm._v(" "),_c('div',{staticClass:"c-imagery__thumbs-wrapper",class:[
             { 'is-paused': _vm.isPaused && !_vm.isFixed },
             { 'is-autoscroll-off': !_vm.resizingWindow && !_vm.autoScroll && !_vm.isPaused }
         ]},[_c('div',{ref:"thumbsWrapper",staticClass:"c-imagery__thumbs-scroll-area",on:{"scroll":_vm.handleScroll}},_vm._l((_vm.imageHistory),function(image,index){return _c('div',{key:image.url + image.time,staticClass:"c-imagery__thumb c-thumb",class:{ selected: _vm.focusedImageIndex === index && _vm.isPaused },on:{"click":function($event){_vm.setFocusedImage(index, _vm.thumbnailClick)}}},[_c('a',{attrs:{"href":"","download":image.imageDownloadName},on:{"click":function($event){$event.preventDefault();}}},[_c('img',{staticClass:"c-thumb__image",attrs:{"src":image.url}})]),_vm._v(" "),_c('div',{staticClass:"c-thumb__timestamp"},[_vm._v(_vm._s(image.formattedTime))])])})),_vm._v(" "),_c('button',{staticClass:"c-imagery__auto-scroll-resume-button c-icon-button icon-play",attrs:{"title":"Resume automatic scrolling of image thumbnails"},on:{"click":function($event){_vm.scrollToRight('reset')}}})])])}
var staticRenderFns = []

export { render, staticRenderFns }